<template>
    <div class="login flex-align" v-wechat-title="`京蒙教育协作智慧平台`">
        <ul class="header flex-align-between">
            <li>
                <a href="https://news.ys.cnuen.net/#/home" class="logo-bd" target="_blank" rel="noopener noreferrer">
                    <img src="@/assets/jmlogo.jpg" alt />
                </a>
            </li>
        </ul>
        <!-- 登录主体 -->
        <div class="container" ref="container">
            <div class="form-section">
                <h2 class="welcome">欢迎登录！</h2>
                <div class="tabs tc">
                    <button class="tab" :class="{ 'active-tab': isUserNameForm == index }"
                        v-for="(item, index) in ['密码登录', '验证码登录']" :key="index" @click="toggle(index)">
                        {{ item }}
                    </button>
                </div>
                <!-- 账号密码登录 start -->
                <el-form v-if="!isUserNameForm" key="pwdForm" :rules="pwdFormRule" :model="pwdForm" status-icon
                    ref="pwdForm" label-width="80px" class="login-form" @submit.native.prevent hide-required-asterisk>
                    <el-form-item label="用户名：" prop="username">
                        <el-input type="text" v-model="pwdForm.username" maxlength="11" autocomplete="off"
                            placeholder="请输入手机号码或用户名" id="userName"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password">
                        <el-input type="password" id="pwd" v-model="pwdForm.password" autocomplete="off"
                            placeholder="请输入登录密码" show-password>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
                        <el-input v-model="pwdForm.verifyCode" id="verifycode" placeholder="请输入验证码"></el-input>
                        <div class="validate-code csp" @click="getCpatcha()">
                            <img :src="captcha" alt="" class="captcha">
                            <!-- <Identify :identifyCode="identifyCode"></Identify> -->
                        </div>
                    </el-form-item>
                    <el-form-item label prop="type" class="summary" label-width="0">
                        <el-checkbox-group v-model="pwdForm.type">
                            <el-checkbox label="记住密码" name="type"></el-checkbox>
                            <el-link type="primary" @click="$router.push({ name: 'forgetpwd' })">忘记密码？</el-link>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item class="btn-box" label-width="0">
                        <el-button type="primary" id="submit" @click="submitForm('pwdForm')" class="login-btn">登录
                        </el-button>
                    </el-form-item>
                </el-form>
                <!-- 账号密码登录 end -->


                <!-- 手机号登录 start -->
                <el-form v-else key="verifyForm" :rules="verifyFormRule" :model="verifyForm" status-icon ref="verifyForm"
                    label-width="80px" class="login-form" @submit.native.prevent hide-required-asterisk>
                    <el-form-item label="手机号：" prop="phone">
                        <el-input type="text" v-model="verifyForm.phone" maxlength="11" autocomplete="off" id="phone"
                            placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
                        <el-input v-model="verifyForm.verifyCode" id="tel-phone" placeholder="请输入手机验证码">
                        </el-input>
                        <el-button class="getVerfiyCode" @click="getCode" v-if="showCode" :disabled="codeDisabled">获取验证码
                        </el-button>
                        <el-button class="getVerfiyCode" @click="getCode" v-else>{{ `重新发送(${count}s)` }}</el-button>
                    </el-form-item>
                    <el-form-item class="btn-box" label-width="0">
                        <el-button type="primary" id="tel-phone" @click="submitForm('verifyForm')" class="login-btn">
                            登录
                        </el-button>
                    </el-form-item>
                </el-form>
                <!-- 手机号登录 end -->

                <div class="other-login flex-align-center">
                    <div class="item">
                        <!-- <p class="other-login csp" @click="toBind">使用银川智慧教育平台账号登录</p> -->
                        <!-- <p class="other-login csp" @click="toBindYcDingDing">使用宁教云账号登录</p> -->
                        <!-- <router-link to="/login/bindAccount" class="other-login">使用银川智慧教育平台账号登录</router-link> -->
                        <!-- <a href="/login/bindAccount" class="other-login">使用银川智慧教育平台账号登录</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 选择账号真实姓名弹窗 -->
        <el-dialog :visible.sync="choiceRealNameVisible" width="657px" class="dialog-realName-wrapper"
            :close-on-click-modal="false">
            <div class="dialog-realName">
                <p class="realName-tips">请选择您要使用账号人的真实姓名：</p>
                <ul class="realName-ul">
                    <li class="realName-li" :class="{ 'selected': selectedRealName.id == item.id }"
                        v-for="item in realNameList" :key="item.id" @click="selectedRealName = item">
                        <img class="realName-li-avatar" :src="downloadURL + item.avatar" alt=""
                            :onerror="$store.state.user.defaultAvatar">
                        <i class="el-icon-success" v-show="selectedRealName.id == item.id"></i>
                        <p class="realName-li-name">{{ item.name }}</p>
                    </li>
                </ul>
                <el-button class="realName-btn" @click="commitRealName">确认</el-button>
            </div>
        </el-dialog>
        <!-- 虚拟账号提示手机号登录 -->

    </div>
</template>

<script>
import Identify from "./component/identify";
import axios from 'axios'
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    name: "login",
    components: {
        Identify,
    },
    data() {
        // 验证手机号格式
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else if (!this.phoneReg.test(value)) {
                return callback(new Error('手机号格式错误'));
            } else {
                this.codeDisabled = false;
                return callback();
            }
        };
        // 验证 手机验证码
        var checkCode = (rule, value, callback) => {
            // console.log(value)
            if (!value) {
                return callback(new Error('验证码不能为空'));
            } else {
                let params = {
                    phone: this.verifyForm.phone,
                    code: String(value)
                }
                this.$Api.Login.checkCode(params).then((resData) => {
                    //  console.log(resData)
                    if (resData.data.result) {
                        return callback();
                    } else {
                        this.$message({
                            message: resData.data.msg,
                            type: 'error'
                        });
                        return callback(new Error('验证码错误'));
                    }
                })
            }
        };
        // 验证 密码登录 验证码
        var checkVerifyCode = (rule, value, callback) => {
            // if (window.location.host !== 'cdnyun.cnuen.net') {
            //   return callback();
            // }
            /**请勿删除 需要 验证码验证时 使用以下代码 */
            if (!value) {
                return callback(new Error('验证码不能为空'));
                // } else if (value != this.identifyCode) {
                //   return callback(new Error('验证码错误'));
                // } else {

            } else {
                return callback();
            }
        };

        return {
            isUserNameForm: 0, // 当前表单 [0 用户名表单 / 1 手机验证码表单]
            // activeForm: false, //当前表单 [false 用户名密码表单 / true 手机验证码表单]
            identifyCode: "",
            pwdForm: { //账号密码登录表单项
                password: "",
                username: "",
                verifyCode: "",
                type: []
            },
            pwdFormRule: { //账号密码登录 验证规则
                username: [{
                    required: true,
                    trigger: "blur",
                    message: "账号不能为空",
                }],
                // password: [{
                //     required: true,
                //     trigger: "blur",
                //     message: "密码不能为空",
                // }],
                verifyCode: [{
                    required: false,
                    trigger: "blur",
                    validator: checkVerifyCode
                }],
            },
            verifyForm: { //手机号登录表单项
                phone: "",
                verifyCode: "",
            },
            verifyFormRule: { //手机号登录 验证规则
                phone: [{
                    required: true,
                    trigger: "change",
                    validator: checkPhone
                }],
                verifyCode: [{
                    required: true,
                    message: "请输入验证码",
                    trigger: "blur",
                    validator: checkCode
                }],
            },
            captcha: '', //用户名登录图形验证码
            keyCode: '', //图形验证码key
            phoneReg: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
            codeDisabled: true, //验证码禁用状态 
            showCode: true, //验证码按钮显隐状态
            count: 0, //倒计时 - 计数器
            timer: "", //倒计时 - 计时器
            choiceRealNameVisible: false, //选择真实姓名弹窗状态
            realNameList: [],
            selectedRealName: {},
            isFreeLogin: false,
            freeLoginPath: undefined,
            freeLoginParam: {},
            vphone: ''
        };
    },
    computed: {
        ...mapState({
            loadingConfig: state => state.user.loadingConfig,
            token: state => state.user.token,
            userInfo: state => state.user.userInfo,
            roleList: state => state.user.roleList,
        }),
    },
    methods: {
        // vuex mutation
        ...mapMutations({
            setToken: 'getToken',
            setFileToken: 'getFileToken',
            setUserInfo: 'getUserInfo',
            setRoleList: 'getUserRoleList',
            setIsAdmin: 'getIsAdmin'
        }),
        // 切换登录方式
        toggle(index) {
            // this.activeForm = !this.activeForm;
            // this.activeForm ? this.resetForm('pwdForm') : this.resetForm('verifyForm')
            this.isUserNameForm = index;
            let activeFormName = this.isUserNameForm ? 'pwdForm' : 'verifyForm';
            this.$refs[activeFormName].resetFields();
            this.codeDisabled = true;
        },
        // 获取图片验证码 
        async getCpatcha() {
            this.$set(this.pwdForm, 'verifyCode', '');
            let resData = await this.$Api.Login.getCpatcha();
            // console.log(resData);
            if (!resData || JSON.stringify(resData.data) == '{}') return;
            this.captcha = resData.data.image;
            this.keyCode = resData.data.keyCode;
        },
        // 刷新用户密码登录验证码
        // refreshCode() {
        //   this.identifyCode = ""; // 验证码清空
        //   let identifyRange = "0123456789"; // 验证码选取范围
        //   for (let i = 0; i < 4; i++) {
        //     this.identifyCode += identifyRange[Math.floor(Math.random() * identifyRange.length)];
        //   }
        // },
        // 获取手机验证码
        async getCode() {
            this.countDown();
            let params = {
                phone: this.verifyForm.phone
            }
            let resData = await this.$Api.Login.getVerifyCode(params)
            // console.log(resData);
            this.$message({
                message: resData.code === 200 ? '验证码发送成功' : resData.msg,
                type: resData.code === 200 ? 'success' : 'error'
            });
        },
        // 去绑定账户（第三方登录）
        async toBindYcDingDing() {
            let params = {
                type: 'ycDingDingThird',
                path: `https://${window.location.host}/login/bindYcDingDingAccount`
            }
            let resData = await this.$Api.Home.loginThird(params)
            console.log(resData);
            if (resData.code == 200 && resData.data) {
                window.location.href = resData.data.redirectUrl
            }
        },
        // 倒计时
        countDown() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.showCode = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.showCode = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        // 点击验证表单
        submitForm(formName) {
            console.log('formName:', formName)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.commitForm(formName);
                } else {
                    this.$message.error('请正确填写信息');
                    return false;
                }
            });
        },
        // 表单提交
        async commitForm(formName) {
            const loading = this.$loading(this.loadingConfig);

            let params = {};
            let resData = {};

            // 用户名、密码登录
            if (formName === 'pwdForm') {
                let data = {
                    username: this[formName].username,
                    password: this[formName].password,
                }
                // 判断是否是虚拟账号
                let vresData = await this.$Api.Login.isVirtua(data)
                console.log('判断是否是虚拟账号:', vresData)
                if (vresData.data) {
                    loading.close();
                    console.log('是虚拟账号:', vresData);
                    // 虚拟账号登录
                    let datas = {
                        username: this[formName].username,
                        password: this[formName].password,
                        keyCode: this.keyCode,
                        captchaCode: this[formName].verifyCode
                    }
                    let vloginresData = await this.$Api.Login.vpclogin(datas)
                    console.log("虚拟账号登录:", vloginresData);
                    if (!vloginresData.data.bind) {
                        // 虚拟账号是第一次登录--->需完善信息
                        this.$router.push({
                            path: '/updateUserInfo',
                            query: {
                                tempToken: vloginresData.data.temp_token
                            }
                        })
                    } else {
                        // 虚拟账号不是第一次登录--->提示用手机号登录
                        this.vphone = vloginresData.data.phone;
                        this.$confirm(`请用手机号${this.vphone}登录`, '提示', {
                            showCancelButton: false,
                            showConfirmButton: true,
                            type: 'warning'
                        }).then(() => {
                            this.getCpatcha()
                        })
                    }
                } else {
                    console.log('登录keyCode:', this.keyCode,)
                    params = {
                        username: this[formName].username,
                        password: this[formName].password,
                        keyCode: this.keyCode,
                        captchaCode: this[formName].verifyCode
                    }
                    resData = await this.$Api.Login.login(params);
                }
            }

            //手机验证码登录 
            if (formName === 'verifyForm') {
                params = {
                    phone: this[formName].phone,
                    code: this[formName].verifyCode
                }
                resData = await this.$Api.Login.loginByPhone(params);
            }
            // console.log(resData)
            if (resData.code === 200 && resData.data.access_token) {
                this.setToken(resData.data.access_token);
                sessionStorage.setItem('token', resData.data.access_token)
                // 多用户判断
                if (!resData.data.member || resData.data.member == 1) { // 账户身上只有一个用户 || 账户身上不存在用户 =>直接登录
                    this.waitReturnValue();
                } else { // 账户身上有多个用户 => 选择要登录的用户
                    this.choiceRealNameVisible = true;
                    this.getRealNameList();
                }
            }
            if (resData.code !== 200) {
                this.$message.error(resData.data.msg);
                this.getCpatcha();
            }
            loading.close();
        },
        // 获取真实姓名列表
        async getRealNameList() {
            let resData = await this.$Api.Login.getRealNameList();
            this.realNameList = resData.data.members
        },
        async commitRealName() {
            let params = {
                memberId: this.selectedRealName.id
            }
            let resData = await this.$Api.Login.getMemberToken(params);
            // console.log(resData);
            if (resData.code === 200 && resData.data.access_token) {
                this.setToken(resData.data.access_token)
                this.waitReturnValue();
            } else {
                this.$message.error(resData.data.msg);
            }

        },
        // 获取文件预览token 
        async getFileToken() {
            let resData = await this.$Api.Resources.getFileToken();
            this.setFileToken(resData.data.token)
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 查询登录用户是否为管理员(云端答疑的管理员身份)
        async getUserIsAdmin() {
            let resData = await this.$Api.Login.isAdmin();
            this.setIsAdmin(resData.data)
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 获取用户信息
        async getUserinfo() {
            let resData = await this.$Api.Home.userInfo();
            // console.log(resData);
            if (JSON.stringify(resData.data) == '{}') {
                this.$message.error('获取个人信息失败，请联系管理员');
                this.$store.dispatch('resetState');
                return;
            }
            this.setUserInfo(resData.data);
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 获取用户身份
        async getRoles() {
            let resData = await this.$Api.Home.getroles();
            if (resData.data.length <= 0) {
                this.$message.error('获取个人信息失败，请联系管理员');
                this.$store.dispatch('resetState');
                return;
            }
            this.setRoleList(resData.data);
            return new Promise((resolve, reject) => {
                resolve(resData)
            })
        },
        // 等待接口完成
        waitReturnValue() {
            let userInfo = this.getUserinfo();
            let roles = this.getRoles();
            let fileToken = this.getFileToken();
            let isAdmin = this.getUserIsAdmin();
            Promise.all([userInfo, roles, fileToken, isAdmin]).then((values) => {
                // console.log(values);
                this.$notify.success({
                    title: "登陆成功",
                    duration: 2500
                });
                if (window.qt && Cookies.get('token')) {
                    this.$router.push(Cookies.get('path'));
                    Cookies.remove('path')
                } else {
                    if (this.$route.query.origin == 'jm') {
                        console.log(this.$route.query);
                        this.getProjectInfo(this.$route.query.projectId);
                        if (this.$route.query.goPath == 'course') {
                            this.$router.push(`/course/${this.$route.query.courseId}`);
                            sessionStorage.setItem('projectId', this.$route.query.projectId)
                        } else if (this.$route.query.goPath == 'space') {
                            this.$router.push({
                                path: "/schoolSpace",
                                query: {
                                    schoolId: this.$route.query.schoolId,
                                    schoolName: this.$route.query.schoolName,
                                }
                            });
                        } else if (this.$route.query.goPath == 'alliance') {
                            this.$router.push({
                                path: "/alliance3",
                            });

                        } else {
                            this.$router.push("/main/266/project");
                        }
                    } else if (this.$route.query.origin == 'yanshi') {
                        console.log('演示')
                        this.$router.push("/home/report?type=yanshi");
                    } else if (this.isFreeLogin) {
                        // console.log(this.freeLoginPath);
                        let param = JSON.parse(this.freeLoginParam);
                        // console.log('params', param);
                        axios.defaults.headers['projectId'] = param.projectId
                        this.getSecondList(param.projectId);
                    } else if (this.$route.query.path == 'my') {
                        this.$router.push('/my')
                    } else if (this.$route.query.origin == 'cnueb') {
                        this.getProjectInfo(this.$route.query.projectId);
                        if (this.$route.query.goPath == 'course') {
                            this.$router.push(`/course/${this.$route.query.courseId}`);
                            sessionStorage.setItem('projectId', this.$route.query.projectId)
                        }else{
                            this.$router.push("/item-list");
                        }
                    }
                    else {
                        this.$router.push("/item-list");
                    }
                }
            }).catch((reason) => {
                console.log(reason);
            });
        },
        //获取免登录项目数据
        async getSecondList(projectId) {
            let that = this;
            let resData = await this.$Api.DST.getlistBySecondId(projectId);
            if (JSON.stringify(resData.data) == '[]' || !resData) return;
            // console.log('projectId获取数据', resData);
            // 存储项目数据
            resData.data.map(item => {
                item.children.map(each => {
                    if (each.id == projectId) {
                        that.$store.commit('getCurrentProject', each);
                    }
                })
            })
            // 存储身份
            this.$store.commit('getRole', 'teacher');
            this.$store.state.user.userRoleList.map(item => {
                if (item.value == 'teacher') {
                    this.$store.commit('getRoleId', item.id);
                }
            })
            this.$router.push(this.freeLoginPath);
        },
        // 按回车登录
        enterLogin() {
            let key = !window.event ? e.keyCode : window.event.keyCode;
            let submitFormName = key === 13 && !this.isUserNameForm ? 'pwdForm' : key === 13 && this
                .isUserNameForm ? 'verifyForm' :
                '';
            if (submitFormName) {
                this.submitForm(submitFormName);
            }
        },
        // 计算登录表单的位置
        formPosition() {
            if (!this.$refs.container) return;
            let addVal = document.body.clientWidth == 1920 ? 47 : 0;
            let right = parseInt((document.body.clientWidth / 2 - 473) / 2 + addVal);
            this.$refs.container.style.marginRight = `${right}px`
        },
        // 关联账号成功回调
        relevance(resData) {
            console.log('关联账号成功回调:', resData)
            if (resData.data.access_token) {
                this.setToken(resData.data.access_token)
                // 多用户判断
                if (!resData.data.member || resData.data.member == 1) { // 账户身上只有一个用户 || 账户身上不存在用户 =>直接登录
                    this.waitReturnValue();
                } else { // 账户身上有多个用户 => 选择要登录的用户
                    this.choiceRealNameVisible = true;
                    this.getRealNameList();
                }
            } else {
                this.$message.error(resData.data.msg);
                this.getCpatcha();
            }
            loading.close();
        },
        getProjectInfo(projectId) {
            let data = {
                projectId
            }
            this.$Api.Score.projectInfo(data)
                .then(res => {
                    console.log('项目详情res:', res);
                    this.$store.commit('getCurrentProject', res.data);
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 首师优字免密登录
        async freeLogin() {
            const loading = this.$loading(this.loadingConfig);
            let params = this.$route.query;
            this.freeLoginParam = this.$route.query.param;
            let resData = await this.$Api.Login.thirdOrgLogin(params);
            // console.log(resData);
            if (resData.code === 200 && resData.data.access_token) {
                this.isFreeLogin = true;
                this.freeLoginPath = resData.data.path.substring(resData.data.path.indexOf('/', 8))
                // console.log('免密登录地址', this.freeLoginPath)
                this.setToken(resData.data.access_token)
                // 多用户判断
                if (!resData.data.member || resData.data.member == 1) { // 账户身上只有一个用户 || 账户身上不存在用户 =>直接登录
                    this.waitReturnValue();
                } else { // 账户身上有多个用户 => 选择要登录的用户
                    this.choiceRealNameVisible = true;
                    this.getRealNameList();
                }
            }
            if (resData.code !== 200) {
                this.$message.error(resData.data.msg);
            }
            loading.close();

        },
        // 教育云-判断是否绑定
        getBindInfo() {
            let data = {
                ticket: this.$route.query.ticket
            }
            this.$Api.Login.getBindInfo(data)
                .then(res => {
                    console.log('判断教育云是否绑定:', res);
                    if (res.data.bind) {
                        // 绑定过直接走登录
                        console.log('绑定过直接登录');
                        this.setToken(res.data.access_token)
                        this.waitReturnValue();
                    } else {
                        // 没绑定过，去完善资料
                        console.log('没绑定过需完善资料');
                        this.$router.push({
                            path: '/updateUserInfoCloud',
                            query: {
                                tempToken: res.data.temp_token
                            }
                        })
                    }
                })
                .catch(err => {
                    console.log('判断教育云是否绑定err:', err)
                })
        },
        // 京蒙官网跳转京蒙研修平台

    },
    created() {
        if (this.token) this.$router.push('/item-list')
    },
    mounted() {
        // this.refreshCode();
        if (this.$route.query.goPath == 'space') {

        } else if (this.$route.query.goPath == 'alliance') {

        } else if (this.$route.query.origin == 'jm') {

        } else {
            this.getCpatcha();
        }

        window.addEventListener('keyup', this.enterLogin, true)

        this.$nextTick(() => {
            this.formPosition();
        })

        window.onresize = () => {
            this.formPosition();
        }
        if (window.qt) {
            if (Cookies.get('token')) {
                this.$store.commit('getToken', Cookies.get('token'));
                this.getUserinfo();
            }
            Cookies.remove('token')
        }
        // 判断是否是第三方登录
        if (this.$route.query.isThirdLogin) {
            console.log('第三方登录');
            this.relevance(this.$route.query.data)
        }
        // 判断是否是京蒙自动登录
        if (this.$route.query.origin == 'jm' || this.$route.query.origin == 'yanshi') {
            console.log('京蒙自动登录');
            this.pwdForm.username = this.$route.query.un;
            this.pwdForm.password = this.$route.query.pd;
            this.pwdForm.verifyCode = this.$route.query.captchaCode;
            this.keyCode = this.$route.query.keyCode;
            console.log('keyCode:', this.keyCode);
            this.commitForm('pwdForm')
        }
        // console.log(this.$route.query)
        // 首师优字免密登录
        if (this.$route.query.secretId) {
            this.freeLogin();
        }
        // 判断是否是教育云登录
        if (this.$route.query.ticket) {
            this.getBindInfo()
        }
        // 判断是否是京蒙首页-智慧研修登录
        if (this.$route.query.origin == 'cnueb') {
            this.setToken(this.$route.query.access_token);
            sessionStorage.setItem('token', this.$route.query.access_token)
            // 多用户判断
            if (this.$route.query.member == 0 || this.$route.query.member == 1 || this.$route.query.member == 'null') { // 账户身上只有一个用户 || 账户身上不存在用户 =>直接登录
                console.log('账户身上只有一个用户')
                this.waitReturnValue();
            } else { // 账户身上有多个用户 => 选择要登录的用户
                this.choiceRealNameVisible = true;
                this.getRealNameList();
            }
        }
    },
    destroyed() {
        window.removeEventListener('keyup', this.enterLogin, true);
    },
};
</script>

<style lang="less" scoped>
@import "./less/login.less";
</style>
